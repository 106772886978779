body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  font-family: 'Open Sans';
  margin-top: 7rem;
  min-width: 900px;
}

* {
  margin:0;
}

#root {
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./fonts/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: bolder;
  src: local('Open Sans'), url(./fonts/OpenSans-ExtraBold.ttf) format('truetype');
}